import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class WhatMake extends React.Component {


  render() {

    return (
      <section className="what-make pt-100 mb-0">   
        <div className='gray-bg'>
        <div className="container">
          <h5 className='heading-h2 text-center'><span className='bluecolor'>Technologies</span> We Use For Blockchain Development</h5>
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/blockchain/stacks.webp"
                alt="Top Reason to choose white lable crypto wallet"
                placeholder='none'
                width={1200}
              />
            {/* <h3 className='text-center dev-base'>Blockchain Development Based on <span className='bluecolor'>Industry Standards</span></h3>
            <div className='d-flex flex-wrap justify-content-evenly ind-std'>
                <p>On-time assistance and Support</p>
                <p>Technical Competency</p>
                <p>Interactive Design</p>
                <p>Promotional Tactics</p>
                <p>Agile Framework</p>
            </div> */}
        </div>
        </div>
      </section>
    )
  }
}

export default WhatMake