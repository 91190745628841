import React from "react"

class Kickoff extends React.Component {

  render() {
    return (

      <section className="benifor kickoff pt-100 mb-0">
          <div className="container"> 
            <h5 className="heading-h2 text-center">
            Get Started Now, <span className="bluecolor">Your Success Awaits !!</span>
            </h5>
            <p className="text-center">
            Take the lead to shape your business vision into successful projects. With our expertise and streamlined blockchain development services, experience the potential growth and amazing benefits.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square">
                <p className="head3">Contact us</p>
                <p className="pharagraph">
                Fill out the form below to submit your proposal. We would like to hear your project ideas.
                </p>
              </div>
              <div className="square">
                <p className="head3">Call Consultation</p>
                <p className="pharagraph">
                Talk to our blockchain consultation specialist or let our team call back to you to know about your project goals.
                </p>
              </div>
              <div className="square">
                <p className="head3">Cost Estimation</p>
                <p className="pharagraph">
                Get your approximate cost breakup and time frame based on your project proposal requirements free of cost.
                </p>
              </div>
              <div className="square">
                <p className="head3">Project On</p>
                <p className="pharagraph">
                On board with us as our loyal client. Let us share our technical expertise to kickstart your project!
                </p>
              </div>
            </div>
            <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Click here to get started</a>
          </div>
      </section>

    )
  }
}

export default Kickoff
